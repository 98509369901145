import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DevelopmentDetails from "../components/development-details"
import HeaderDevelop from "../components/header-develop"
import FooterNav from "../components/footer-nav"

const DevelopPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <HeaderDevelop />
    <SEO
      title="Development | MASS Blockchain Consensus Engine" keywords={[`MASS Blockchain Consensus Engine`]}
      lang={locale}
    />
    <DevelopmentDetails />
    <FooterNav />
  </Layout>
)

DevelopPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default DevelopPage
